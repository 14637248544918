<template>
  <section id="main-wrapper" style="min-height: 95vh">
    <b-container>
      <b-row
        style="padding: 5px"
        v-if="
          this.$store.state.md_fire.st_user &&
          this.$store.state.md_fire.st_user.account
        "
      >
        <b-col cols="8"
          ><span class="d-flex justify-content-start"
            >Notifications</span
          ></b-col
        >
        <b-col cols="4">
          <div class="d-flex justify-content-end">
            <toggle-button
              :labels="true"
              v-model="notifications"
              @change="toggleNotifications"
              :sync="true"
            />
          </div>
        </b-col>
      </b-row>
      <b-row style="padding: 5px">
        <b-col cols="8"
          ><span class="d-flex justify-content-start">Dark mode</span></b-col
        >
        <b-col cols="4">
          <div class="d-flex justify-content-end">
            <toggle-button
              :labels="true"
              v-model="darkmode"
              @change="toggleDarkMode"
              :sync="true"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor,
} from "@capacitor/core";
import { fb_usersCollection } from "@/lib-core/lib-firebase";
const { PushNotifications, LocalNotifications, Storage } = Plugins;

export default {
  name: "cmp-settingsmobile",
  mounted() {
    this.onloadDarkMode();
    this.onloadNotifications();
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1500);
  },
  data() {
    return {
      darkmode: false,
      notifications: true,
    };
  },
  computed: {},
  methods: {
    async onloadNotifications() {
      const notificationsStorage = await Storage.get({
        key: "notifications",
      });
      if (notificationsStorage.value == null) {
        await Storage.set({
          key: "notifications",
          value: JSON.stringify(true),
        });
        notificationsStorage.value = true;
      }
      const notifications =
        notificationsStorage != null
          ? JSON.parse(notificationsStorage.value)
          : false;
      this.notifications = notifications;
    },

    async toggleNotifications(value) {
      if (value.value) {
        this.activateNotifications();
        await Storage.set({
          key: "notifications",
          value: JSON.stringify(true),
        });
      } else {
        this.deactivateNotifications();
        await Storage.set({
          key: "notifications",
          value: JSON.stringify(false),
        });
      }
    },
    async deactivateNotifications() {
      if (
        this.$store.state.md_fire.st_user &&
        this.$store.state.md_fire.st_user.account
      ) {
        PushNotifications.removeAllListeners();
        this.$store.commit("md_custom/mt_notifications", {
          user: this.$store.state.md_fire.st_user.account.email,
          notifications: false,
        });
        this.notifications = false;
      }
    },
    async activateNotifications() {
      if (
        this.$store.state.md_fire.st_user &&
        this.$store.state.md_fire.st_user.account
      ) {
        if (Capacitor.platform !== "web") {
          PushNotifications.requestPermission().then((permission) => {
            if (permission.granted) {
              PushNotifications.register();
              // Register with Apple / Google to receive push via APNS/FCM
            } else {
              // No permission for push granted
            }
          });

          PushNotifications.addListener("registration", function (token) {
            fb_usersCollection
              .doc(this.$store.state.md_fire.st_user.account.email)
              .set({ token: token }, { merge: true });
            if (Capacitor.platform === "android") {
              const notificationChannel = {
                id: "default",
                name: "Default",
                description: "default",
                importance: 5,
                visibility: 1,
              };
              const notificationChannel2 = {
                id: "channel2",
                name: "Pop notifications",
                description: "Pop notifications",
                importance: 5,
                visibility: 1,
              };

              PushNotifications.createChannel(notificationChannel);
              LocalNotifications.createChannel(notificationChannel);
              PushNotifications.createChannel(notificationChannel2);
              LocalNotifications.createChannel(notificationChannel2);
            }
          });

          PushNotifications.addListener(
            "pushNotificationReceived",
            async (notification) => {
              if (Capacitor.platform === "android") {
                LocalNotifications.schedule({
                  notifications: [
                    {
                      title: notification.title,
                      body: notification.body,
                      id: new Date().getUTCMilliseconds(),
                      schedule: {
                        at: new Date(Date.now() + 1000),
                      },
                      extra: notification.data,
                      channelId: "channel2",
                    },
                  ],
                });
              }
            }
          );
        }
        this.$store.commit("md_custom/mt_notifications", {
          user: this.$store.state.md_fire.st_user.account.email,
          notifications: true,
        });
        this.notifications = true;
      }
    },
    async onloadDarkMode() {
      const darkmodeStorage = await Storage.get({
        key: "darkmode",
      });
      if (darkmodeStorage.value == null) {
        await Storage.set({
          key: "darkmode",
          value: JSON.stringify(false),
        });
        darkmodeStorage.value = false;
      }

      const darkmode =
        darkmodeStorage != null ? JSON.parse(darkmodeStorage.value) : false;
      this.darkmode = darkmode;
    },
    async toggleDarkMode(value) {
      if (value.value) {
        await Storage.set({
          key: "darkmode",
          value: JSON.stringify(true),
        });

        this._addDarkTheme();
      } else {
        await Storage.set({
          key: "darkmode",
          value: JSON.stringify(false),
        });
        this._removeDarkTheme();
      }
    },
    _addDarkTheme() {
      let darkThemeLinkEl = document.createElement("link");
      darkThemeLinkEl.setAttribute("rel", "stylesheet");
      darkThemeLinkEl.setAttribute("href", "/css/darktheme.css");
      darkThemeLinkEl.setAttribute("id", "dark-theme-style");
      let docHead = document.querySelector("head");
      docHead.append(darkThemeLinkEl);
      this.darkmode = true;
    },
    _removeDarkTheme() {
      let darkThemeLinkEls = document.querySelectorAll("[id=dark-theme-style]");
      var darkThemeLinkElArray = [...darkThemeLinkEls]; // converts NodeList to Array
      darkThemeLinkElArray.forEach((link) => {
        let parentNode = link.parentNode;
        parentNode.removeChild(link);
      });
      this.darkmode = false;
    },
  },
};
</script>

<style>
</style>